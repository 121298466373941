import {
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAIL,
  FILTER_PRODUCTS,
  FETCH_HEADER_CATEGORIES,
  FETCH_ALL_CATEGORIES,
  FETCH_FOOTER_CATEGORIES,
  FETCH_NEW_IN_MENU,
  UPDATE_FILTER_PRODUCTS,
  FETCH_PRODUCTS_START,
  FETCH_HOMEPAGE_PRODUCTS,
  FETCH_SALEPAGE_PRODUCTS,
  FILTER_SALEPAGE_PRODUCTS,
  FETCH_NEXT_PRODUCTS_SUCCESS,
  FETCH_PREV_PRODUCTS_SUCCESS,
} from "./types";

const initialState = {
  error: "",
  categories: [],
  products: [],
  headerCategories: {},
  newInMenu: {},
  footerCategories: [],
  filteredProducts: [],
  filteredSalepageProducts: [],
  showResult: false,
  loading: false,
  homepageProducts: [],
  salepageProducts: [],
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_PRODUCTS_SUCCESS: {
      return {
        ...state,
        products: action.payload,
        filteredProducts: action.payload.data,
        loading: false,
        error: "",
      };
    }
    case FETCH_NEXT_PRODUCTS_SUCCESS: {
      return {
        ...state,
        // products: action.payload,
        filteredProducts: [...state.filteredProducts, ...action.payload.data],
        loading: false,
      };
    }
    case FETCH_PREV_PRODUCTS_SUCCESS: {
      return {
        ...state,
        products: action.payload,
        filteredProducts: [...action.payload.data, ...state.filteredProducts],
        loading: false,
      };
    }
    case FETCH_ALL_CATEGORIES: {
      return {
        ...state,
        categories: action.payload,
      };
    }
    case FETCH_HEADER_CATEGORIES: {
      const { category, type } = action.payload;
      const products = state?.categories[`${type}`]?.categories.filter(
        (obj) => obj.category === category
      );
      return {
        ...state,
        headerCategories: products[0],
      };
    }
    case FETCH_NEW_IN_MENU: {
      return {
        ...state,
        newInMenu: action.payload,
      };
    }
    case FETCH_FOOTER_CATEGORIES: {
      return {
        ...state,
        footerCategories: action.payload,
      };
    }

    case FILTER_PRODUCTS: {
      const type = action.payload;
      let result;

      if (type === "ascending" || type === "descending") {
        const sortOrder = type === "ascending" ? 1 : -1;

        result = [...state.products.data].sort((a, b) => {
          return sortOrder * (a.price - b.price);
        });
      }

      return { ...state, filteredProducts: result };
    }

    case FILTER_SALEPAGE_PRODUCTS: {
      const type = action.payload;
      let result;

      if (type === "ascending" || type === "descending") {
        const sortOrder = type === "ascending" ? 1 : -1;

        result = [...state.salepageProducts.data].sort((a, b) => {
          return sortOrder * (a.price - b.price);
        });
      }

      return { ...state, filteredSalepageProducts: result };
    }

    case FETCH_HOMEPAGE_PRODUCTS: {
      return {
        ...state,
        homepageProducts: action.payload,
      };
    }

    case FETCH_SALEPAGE_PRODUCTS: {
      return {
        ...state,
        salepageProducts: action.payload,
        filteredSalepageProducts: action.payload.data,
        loading: false,
      };
    }

    case UPDATE_FILTER_PRODUCTS: {
      return { ...state, filteredProducts: action.payload };
    }
    case FETCH_PRODUCTS_FAIL: {
      return {
        ...state,
        products: [],
        filteredProducts: [],
        error: action.payload,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default productReducer;
